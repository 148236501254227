// Import global styles
@import '../../../../../../global.scss';

.studentPresence__currentSpeaker {
  /* DISPLAY */
  display: flex;
  justify-content: space-between;
  
  margin: 0 0 0.5rem;
  position: relative;
}
.studentPresence__currentSpeaker.hidden {
  display: none;
  visibility: hidden;
}

.currentSpeaker__button {
  /* DISPLAY */
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 1000px;
  box-shadow: $shadow;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  height: 1.75em;
  position: relative;
  width: 1.75em;

  &.bien {
    background: green;
  }
  &.beepboop {
    background: red;
  }
  &.clear {
    background: gray;
  }
  &.hidden {
    background: white;
    cursor: default;
  }
}

.currentSpeaker__button .currentSpeakerButton__feedbackCount {
  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* POSITION */
  position: absolute; /* Positioned relative to .currentSpeaker__button */
  bottom: -0.5em;
  right: -0.5em;

  background: $darkGrey;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 0.5em;
  height: 1.25em;
  line-height: 0.65em;
  width: 1.25em;
}

.currentSpeaker__button .currentSpeakerButton__shortcutHint {
  /* POSITION */
  position: absolute;
  top: -0.25em;
  left: -0.25em;

  background: $darkGrey;
  border: 1px solid $Peach;
  border-radius: 0.25em;
  color: #fff;
  display: inline-block;
  font-size: 0.5em;
  font-weight: 900;
  height: 1em;
  line-height: 1em;
  margin-right: 0.5rem;
  width: 1em;
}

.currentSpeaker__name {
  /* DISPLAY */
  display: flex;
  align-items: center;

  background: $darkGrey;
  border: 1px solid $Peach;
  border-radius: 0.5em;
  box-sizing: border-box;
  box-shadow: $shadow;
  color: $Peach;
  font-weight: 600;
  // overflow: hidden;
  padding-left: 0.25em;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 40%;
}

.currentSpeaker__benchButton {
  $benchButtonFontSize: 0.5rem;

  /* POSITION */
  position: absolute; /* against .currentSpeaker__name */
  bottom: calc( $benchButtonFontSize * -1.5 );
  left: 50%; /* Combined with transform below, centers button horizontally */

  background: $purple;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: $benchButtonFontSize;
  font-weight: 600;
  padding: calc( $benchButtonFontSize / 2 );
  transform: translateX(-50%); /* Combined with left above, centers button horizontally */
  z-index: 1;
}

/* Hide keyboard shortcut hints on mobile */
@media only screen and (max-device-width: 900px) {
  .currentSpeakerButton__shortcutHint {
    display: none;
    visibility: hidden;
  }
}