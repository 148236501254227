.centerer{
  height:100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.kill-drill-table{
  margin:10px;
}

.kill-drill-button{
  background-color:#ffe2c4;
  margin:10px;
  cursor:pointer;
  padding:20px;
  text-align: center;
  color:#1c1c1c;
  text-decoration: none;
  font-size:1.2em;
  min-width:400px;
  border:none;
  border-radius:1.2em;
}

.kill-drill-button[disabled]{
  background-color: grey;
}

.error{
  color: red;
}

.green{
  color: green;
}