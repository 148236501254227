// Import global styles
@import '../../../global.scss';

.pastLessons__day, .todayLessons__past, .todayLessons__current, .todayLessons__future, .futureLessons__day {
  border-radius: 25px;
  box-shadow: $shadow;
  box-sizing: border-box;
  margin: 1rem auto;
  padding: 1.5rem;
  max-width: 90vw;

  h2, h3 {
    margin: 0 auto;
  }
}

.nyc-reminder {
  font-size: 0.7em;
  margin: 0.5rem 0;
}

.pastLessons__day, .todayLessons__past {
  background: #fba;
}

.todayLessons__current {
  background: #bfed91;
}

.todayLessons__future, .futureLessons__day {
  background: $lightPeach;
}

// Adjust padding for smaller viewports
@media only screen and (max-width: 600px) {
  .todayLessons__past, .todayLessons__current, .todayLessons__future-today, .futureLessons__day {
    padding: 1rem;
  }
}