@import "../../global.scss";

.classDetail__container {
  padding: 0 0.5rem;
}

.classDetail__lessonSummaryContainer {
  font-size: 0.8rem;
  margin: 0 auto;
  max-width: 50vw;
  min-width: 340px;
}

.classDetail__innerContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.classDetail__actionsContainer {
  padding: 0 1rem;
  text-align: center;
}

.classDetail__actionButton {
  background: #fff;
  border: 1px solid $Peach;
  border-radius: 1rem;
  box-shadow: none;
  color: $Peach;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin: 0.5rem auto;
  padding: 0.5rem 1rem;

  &--positiveAction {
    background: $Peach;
    color: #fff;
    font-size: 1.25rem;
  }

  &.inactive {
    background: gray;
    border: 1px solid silver;
    color: silver;
    cursor: default;
    text-decoration: line-through;
  }
}

.classDetail__action {
  font-size: 0.8rem;
}
