.engineering__moduleModal {
  /* DISPLAY */
  display: flex;
  justify-content: center;
  align-items: center;

  /* POSITION */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // background is $darkGray with opacity
  background: linear-gradient(rgba(77, 69, 65, 0.9), rgba(77, 69, 65, 0.9)); 
}

.moduleModal__content {
  background: #fff;
  border-radius: 10px;
  font-size: 0.75rem;
  padding: 1rem;
  text-align: center;
  width: 50%;
  min-width: 300px;
}

.moduleModal__button {
  font-size: 1em;
  margin: 1rem;
}

.moduleModal__content .moduleSearchResults {
  /* POSITION */
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  background: #eee;
  border-radius: 0 0 10px 10px;
}