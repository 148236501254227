.moduleSearchContainer {
    position: relative;
}

.moduleSearchForm {
    border-radius: 10px;
    font-size: 1em;
    line-height: 5vh;
    outline-width: 0;
    width: 100%;
}

.moduleSearchResult {
    font-size: 0.8em;
    overflow-wrap: break-word;
    width: 100%;
}