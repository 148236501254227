
.upload-vocabulary-text-area {
    width: 1000px;
    height: 500px;
    font-size: 20px;
}

.upload-vocabulary-button-enabled {
    width: 200px;
    height: 50px;
    font-size: 20px;
    font-weight: 900;
    color: black;
    background-color: #f4ac60;
    cursor: pointer;
}

.upload-vocabulary-button-disabled {
    width: 200px;
    height: 50px;
    font-size: 20px;
    font-weight: 900;
    color: black;
    background-color: #f4ac60;
    cursor: pointer;
    opacity: .5;
}

.module-name {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
    background-color: #f4ac60;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30vw;
}

.display-vocabulary-admin {
    display: grid;
    grid-template-columns: .4fr .4fr;
    width: 40vw;
    margin-left: 20px;
    border: 1px solid black;
    grid-gap: 100px;
}

.display-vocabulary-non-admin {
    display: grid;
    grid-template-columns: .4fr .4fr;
    width: 40vw;
    border: 1px solid black;
    grid-gap: 100px;
    margin-left: auto;
    margin-right: auto;
}

.native-language {
    grid-column: 1;
    font-size: 15px;
    display: inline;
    margin-left: 30px;  
}

.target-language {
    grid-column: 2;
    font-size: 15px;
    display: inline;
}


.upload-vocabulary {
    display: inline;
}

.vocabulary-container {
    display: grid;
    grid-template-columns: .5fr .5fr;
    width: 100vw;
    grid-gap: 100px;
}

.vocabulary--admin {
    grid-column: 1;
    display: block;
}

.vocabulary--non-admin {
    grid-column: 1;
    display: inline;
    width: 100vw;
}

.upload-vocabulary-container {
    grid-column: 2;
    display: inline;
}

.no-vocabulary-uploaded-message-admin {
    margin-left: 40px;
    font-weight: 900;
    color: black;
}

.no-vocabulary-uploaded-message-non-admin {
    text-align: center;
    font-weight: 900;
    color: black;
}

.error-message {
    color: red;
}

.success-message {
    color: green;
}

.instructions {
    font-weight: 900;
    color: black;
    font-size: 25px;
}