// Import variables and fonts
@import "../../global.scss";

.lesson-summary {
  background-color: $lightPeach;
  display: flex;
  flex-direction: column;
  padding: 1vw;

  .lesson-summary__heading {
    text-align: left;
  }

  .lesson-summary__element {
    font-size: 0.8em;
  }
}

.lesson-summary__label {
  font-weight: 600;
}

.lesson-summary__value {
  margin-left: 10px;
  font-weight: 300;
}

.bold-text {
  font-weight: bold;
}
