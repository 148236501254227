// Import global styles
@import '../../../../global.scss';

.communicator__muteButtonContainer {
  position: relative;
  text-align: center;
}

.communicator__muteButton {
  background: $blue;
  border-radius: 1000px;
  box-shadow: $shadow;
  box-sizing: border-box;
  color: white;
  font-size: 0.75rem;
  padding: 0.25em;
  width: 2rem;

  &.negativeState {
    background: $lightBlue;
    box-shadow: 0 3px 6px #ff00007b;
    color: gray;
  }

  &:hover {
    cursor: pointer;
  }
}

.communicator__muteButton .muteButton__shortcutHint {
  /* POSITION */
  position: absolute;
  top: -0.25em;
  left: -4.25em;

  border: 0px solid $Peach;
  border-radius: 0.25em;
  color: #000;
  display: inline-block;
  font-size: 0.7em;
  font-weight: 900;
  height: 1em;
  line-height: 1em;
  margin-right: 0.5rem;
  width: 1em;
}

/* Hide keyboard shortcut hints on mobile */
@media only screen and (max-device-width: 900px) {
  .communicator__button, .muteButton__shortcutHint {
    display: none;
    visibility: hidden;
  }
}