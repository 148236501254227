// Styles only for OneLesson component

// Import variables
@import "../../../global.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

.default-lesson, .past-lesson {
  border-radius: 25px; // matches container div.upcoming-lesson
  margin: 0.25rem auto;
  padding: 0.35rem 0.6rem;

  $white: #fafafa;
  $gray: #ddd;

  &:nth-child(odd) {
    background:$white;
    border: 1px solid $gray;
  }
  &:nth-child(even) {
    background:$white;
    border: 1px solid $gray;
  }
}

// Podcast lessons are Peachy!
.default-lesson.podcast, .past-lesson.podcast {
  background: $Peach;
}

// Video lessons are Peachy!
.default-lesson.video, .past-lesson.video {
  background: $Peach;
}

// Welcome lessons are fresh and green!
.default-lesson.welcome, .past-lesson.welcome {
  background: lightgreen;
}

// 1on1 lessons are fresh and green!
.default-lesson.oneonone, .past-lesson.oneonone {
  background: lightgreen;
}

.mainClassInfo {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.mainClassInfo__inner:hover {
  color: $blue;
  cursor: pointer;
}

// Don't allow sections to wrap
.lesson-level {
  display: inline-block;
  white-space: nowrap;
}

// Lesson Topic
.lessonTopic {
  display: block;
  font-size: 0.8em;
}

// Lesson Type
.lessonType {
  text-align: right;
}

// Styling for message indicating drill has been killed
.lesson--killed {
  color: red;
}

// Styling for teacher change success/error messages
// Also for PDN complete/incomplete flags
.teacherUpdateStatus, .lesson-isNotCompleteFlag, .lesson-isCompleteFlag {
  font-size: 0.8rem;
}
.teacherUpdateStatus--success, .lesson-isCompleteFlag {
  color: green;
}
.teacherUpdateStatus--failure, .lesson-isNotCompleteFlag {
  color: red;
}

.default-lesson, .past-lesson {
  font-size: 0.9em; // to match font size in .unclaimed-lesson and .incomplete-lesson
}

.lessonDetails {
  font-size: .75em;
  margin-bottom: 0.5rem;
}

.lessonDetails__detail {
  margin-bottom: 0.2rem;

  div {
    display: inline-block;
    vertical-align: top;
  }
  div:first-child {
    width: 40%
  }
  div:last-child {
    width: 60%;
  }
}

