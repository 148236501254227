// Import variables
@import "../../../global.scss";

.engineeringContainer {
  /* POSITION */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;

  transition: left 0.5s ease;
  width: 100vw;
  z-index: 5; // To put above viewscreen .screenProtector

  &.isVisible {
    left: 0;
  }
}

.engineeringMenu {
  /* POSITION */
  position: fixed;
  top: 0;
  bottom: 0;

  background: $Peach;
  font-size: 0.75rem;
  padding: .1em 1em .1em 1em;
}

.engineering__button {
  background: #fff;
  border-radius: 1000px;
  box-shadow: $shadow;
  cursor: pointer;
  margin: 1em auto;
  padding: 0.5em;
  text-align: center;
}

.engineering__moduleInfo, .engineering__lessonInfo {
  color: #333;
  font-size: 0.6rem;
  // To keep menu from covering too much of the screen due to long module names
  max-width: min(12rem, 25vw);
}
