.viewscreen {
  box-shadow: 0 0 6px #0000007b;
  flex-grow: 1;
  position: relative;
}
.viewscreen.muteWarning {
  box-shadow: 0 0 6px red;
}

.slidesFrame {
  height: 100%;
  width: 100%;
}

.screenProtector {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
