.manageWordBundles__editWordBundle {
  float: right;
  width: 75%;
}

.wordBundleEditForm__noBundle {
  padding: 1rem;
}

.wordBundleEditForm__container {
  font-size: 16px;
  padding: 0 1rem;
  position: relative;
}
.wordBundleEditForm__container label {
  display: block;
  margin: 0.5em 0;
}
.wordBundleEditForm__container input {
  padding: 0.25em 0.5em 0.25em 0.25em;
  text-overflow: ellipsis;
}
.wordBundleEditForm__container input:read-only:not([type="submit"]) {
  background: silver;
  cursor: default;
}
.wordBundleEditForm__container select {
  font-size: 0.9em;
}

.wordBundleEditForm__heading {
  font-size: 1.5em;
}

.wordBundleEditForm__missingDataNotification {
  color: red;
}

.wordBundleEditForm__missingData {
  background-color: rgba(255, 0, 0, 0.5);
  border-color: red;
}

.wordBundleEditForm__wordsList {
  display: flex;
  flex-flow: row wrap;

  list-style: none;
  margin: 0;
  padding: 0;
}
.wordBundleEditForm__wordsList li {
  border: 1px solid black;
  margin: 0.5em;
  padding: 0.5em;
}

.wordBundleEditForm__addNewWord {
  background: silver;
}

.wordBundleEditForm__submitButton {
  background: red;
  color: white;
}
