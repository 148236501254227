// Import global styles
@import '../../../global.scss';

.incompleteLessons {
  background: #fba;
  border-radius: 25px;
  box-shadow: $shadow;
  margin: 0 1rem;
  padding: 1rem;
}

.incomplete-header {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.incomplete-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.incomplete-lesson{ 
  border: 1px solid red;
  border-radius: 25px;
  font-size: 0.8rem;
  margin: 0.25rem;
  padding: 0.35rem 0.6rem;
  max-width: 380px;
  min-width: 300px;

  $white: #fafafa;
  $gray: #ddd;

  &:nth-child(odd) {
    background:$white;
  }
  &:nth-child(even) {
    background: $gray;
  }
}
