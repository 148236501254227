// Import global styles
@import '../../../../../../../global.scss';

.benchModal__container {
  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* POSITION */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2));
  z-index: 10;
}

.benchModal__inner {
  background: #fff;
  border: 0.25rem solid $Peach;
  border-radius: 10px;
  padding: 1rem;
}

.benchModal__inner p:first-child {
  margin-top: 0;
}

.benchModal__buttonsContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.benchModal__button {
  border: 0;
  border-radius: 1000px;
  box-shadow: $shadow;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  padding: 1em 1.5em;
}

.benchModal__button--confirm {
  background: green;
}

.benchModal__button--cancel {
  background: red;
}