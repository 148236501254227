// Import global styles
@import '../../../global.scss';

.futureLessonsStats {
  background-color: $blue;
  border: 1px solid #fafafa; // to match OneLesson
  border-radius: 25px;
  padding: 0.35rem 0.6rem;

  .statsItem {
    font-size: 20px;
  }
}

.previousLessonsStats {
  background-color: $lightGreen;
  border: 1px solid #fafafa; // to match OneLesson
  border-radius: 25px;
  padding: 0.35rem 0.6rem;

  .statsItem {
    font-size: 20px;
  }
}
