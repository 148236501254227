// Moved here from style.scss for simpler/leaner imports to other files

// Use paths relative to project root, so will work when importing this file
@font-face{
  font-family: ProximaNova;
  src: url("/src/fonts/ProximaNova-Regular.otf") format("otf"),
      url("/src/fonts/ProximaNova-Regular.woff") format("woff"),
}

// Variables (colors)
$Peach: #f4ac60;
$BrightPeach: #ff921d;
$lightPeach: #ffe2c4;
$darkGrey: #4d4541;
$lightGrey: #aaa09b;
$blue: #55bfcd;
$lightBlue: #c1f3f7; 
$lightGreen: rgb(4, 218, 40);
$purple: #5d14bd;

// Box shadow to add depth
$shadow: 0 3px 6px #0000007b;
$shadow-notop: 0px 5px 5px #0000007b;
