@import '../../../../global.scss';

.container {
    margin-top: .75rem;
}

.transitionStep {
    filter: grayscale(1);
  }
  
.transitionStep.accomplished {
    filter: grayscale(0);
}

.customProgressBar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(0, 128, 0, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.indexedStep.accomplished {
    opacity: 0;
}

.imageContainer {
    position: relative;
    display: inline-block; /* Ensures the container fits the image size */
}
  
.indexNumber {
    position: absolute;
    top: 50%; /* Centers the number vertically */
    left: 50%; /* Centers the number horizontally */
    transform: translate(-50%, -50%); /* Centers the number both horizontally and vertically */
    padding: 2px 5px; /* Optional: Adds some padding around the number */
    border-radius: 3px; /* Optional: Rounds the corners of the background */
    font-size: 12px; /* Adjust the font size as needed */
    text-align: center; /* Centers the text within the div */
    width: 30px; /* Matches the image width */
    height: 30px; /* Matches the image height */
    display: flex; /* Use flexbox to center content */
    align-items: center; /* Vertically centers content */
    justify-content: center; /* Horizontally centers content */
}

.indexNumber.accomplished {
    opacity: 0;
}

.supportiveMessage {
    margin-top: 0.25rem;
}