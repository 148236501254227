// Import variables and fonts
@import "../../../global.scss";

.studentSearchBar__input {
  width: 80%;
  padding: .25em;
  font-size: 1em;
  margin-left: auto;
  margin-right :auto;
  border-radius: 0;
}

.stuSearchContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.stuList {
  box-sizing: border-box;
  border-radius: 0;
  width: 80%;
  border-left: solid 2px black;
  border-right: solid 2px black;
  font-size: 0.8em;
}

.dropDownItem {
  cursor: pointer;
}

.dropDownItem:last-child {
  border-bottom: solid 2px black;
  margin-bottom: 0;
  padding-bottom: 1em;
}

.studentSectionList {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.studentSectionText {
  margin: 0.2em;
  min-width: 25em;
  text-align: left;
}
.studentSectionTextMod {
  margin: 0.2em;
  min-width: 25em;
  text-align: left;
  color: blue;
}
.studentSectionTextList{
  margin: 0.2em;
  min-width: 25em;
  text-align: left;
}
.studentSectionTextListHeader{
  margin:0;
}
.studentSectionTextListElement{
  margin:0;
  margin-left:1em;
  min-width:0;
}

.updateButton {
  width: 30vw;
  border-radius: 25px;
  font-size: 1.4em;
  line-height: 10vh;
  background-color: $Peach;
  color: white;
  border: none;
  margin: 20px;
  cursor: pointer;
}

.successMessage {
  color: green;
}

.errorMessage {
  color: red;
}