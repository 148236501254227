@import '../../../../global.scss';

.communicator__joinSoundButtonContainer {
  display: inline-block;
}

.communicator__joinSoundButton {
  background: $blue;
  border-radius: 1000px;
  box-shadow: $shadow;
  box-sizing: border-box;
  color: white;
  font-size: 0.75rem;
  padding: 0.25em;
  width: 2rem;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: darken($blue, 10%);
  }

  &.negativeState {
    background: $lightBlue;
    box-shadow: 0 3px 6px #ff00007b;
    color: gray;
  }
}

.joinSoundButton__shortcutHint {
  font-size: 0.5rem;
  color: #ccc;
  margin-top: 0.2em;
}
