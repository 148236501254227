/**** HOME PAGE *****/
.home-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-instructions{
  width: 100%;
  text-align: center;
  font-size: 0.7em;
}

.home-filters {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;

  // Create gutters around direct child elements
  & > * {
    margin: 1rem;
  }
}

.home-datepicker {
  margin: 1rem auto;
  text-align: center;

  h2 {
    margin-bottom: 0;
  }
}

.home-datepicker__picker {
  margin: 0 auto;
}

.home-datepicker__warning {
  color: red;
}

.home-filterValues:empty {
  display: none;
}

.home-filterValues {
  background-color: #D3E2CB;;
  border: 1px solid #156a1e;
  font-size: .8rem;
  font-weight: 800;
  max-width: 90vw;
  padding: .75rem;
}