.manageWordBundles__wordBundleSearch {
  float: left;
  width: 25%;
}

.wordBundleSearch__searchLabel {
  font-size: 16px; /* Since 1rem is stupid big in this repo */
}

.wordBundleSearch__searchField {
  box-sizing: border-box;
  width: 100%;
}

.wordBundleSearch__resultsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.wordBundleSearch__resultButton {
  cursor: pointer;
  text-align: left;
  width: 100%;
}
.wordBundleSearch__resultButton.selected {
  background: green;
}
