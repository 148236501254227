.feedbackDetailModal__container {
  max-width: 100vw;
}

.feedbackDetailModal {
  display: flex;
  font-size: 0.75rem;
  height: unset;
  max-height: 95vh;
  margin: 1rem auto;
  width: unset;
  max-width: 95vw;

  tr, th {
    text-align: left;
  }
}

.feedbackDetailModal__scrollContainer {
  margin: 0 auto;
  overflow-y: scroll;
}

.feedbackDetailModal__table {
  margin: 0 auto;
  max-width: 100%;

  th, td {
    padding: 0 0.5rem;
  }
}

.feedbackDetailModal__lessonId {
  background: black;
  color: white;
  font-size: 1.1em;
  padding-top: 0.5rem;
}

.feedbackDetailModal__headingsRow th {
  border-bottom: 1px solid black;
}
