// Styling for nav menu, including hamburger menu

// Import variables
@import "../../../global.scss";

// Styling for nav links
.nav-link {
  color: white;
  font-size: 0.8rem;
  margin: 0 0.75rem;
  text-decoration: none;

  &:hover {
    color: $Peach;
    cursor: pointer;
  }
}

// Styling for version note in top right
.nav-version {
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  font-size: 0.5rem;
}

// Styling for hamburger button
// Only rendered at 700px (media query width below)
.nav__burger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    height: 0.25rem;
    background: #fff;
    border-radius: 5px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    // Starter styles for child divs (menu closed state)
    &:first-child {
      margin-left: 0;
      transform: rotate(0);
      width: 100%;
    }

    &:nth-child(2) {
      opacity: 1;
      transform: translateX(0);
    }

    &:nth-child(3) {
      margin-left: 0;
      transform: rotate(0);
      width: 100%;
    }

    // Transitioned styles for child divs (menu open state)
    &.open {
      &:first-child {
        margin-left: 0.25rem;
        transform: rotate(45deg);
        width: 2rem;
      }
  
      &:nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
      }
  
      &:nth-child(3) {
        margin-left: 0.25rem;
        transform: rotate(-45deg);
        width: 2rem;
      }
    }
  }
}

// At 950px wide, compress menu
@media only screen and (max-width: 950px) {
  .nav-link {
    margin: 0 0.4rem;
  }
}

// At 750px wide, convert normal menu to hamburger
@media only screen and (max-width: 750px) {
  .header__nav {
    background: $blue;
    box-shadow: $shadow-notop;
    position: absolute; // positioned against .headerContainer
    left: 0;
    right: 0;
    top: -100vh;
    text-align: center;
    transition: all 0.4s ease-in-out;
    
    // by default, mobile menu is offscreen and invisible
    opacity: 0;

    // slide onscreen and make visible when open class is applied
    &.open {
      opacity: 1;
      top: 100%;
    }

    .nav-link {
      display: block;
      font-size: 1rem;
      margin: 0.65rem;
    }
  }
}