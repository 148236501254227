@import "../../../global.scss";

// 1rem is 23px in this app (standard is 16px), so these values are adjusted down from what you see in the equivalent Speak App component
$presentParticipantListWidth: 10.45rem;
$presentParticipantListToggleButtonHeight: 1.25rem;
$presentParticipantListInnerPadding: 0.25rem;

.presentParticipantList {
  /* DISPLAY */
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  /* POSITION */
  position: absolute;
  bottom: 0;
  right: 0;

  width: $presentParticipantListWidth;
  z-index: 1;
}

.presentParticipantList__inner {
  /* POSITION */
  position: relative;
  bottom: -100vh;

  background: #fff;
  border-left: 1px solid $darkGrey;
  border-right: 1px solid $darkGrey;
  border-top: 1px solid $darkGrey;
  border-radius: 10px 0 0 0;
  font-size: 0.5rem;
  overflow-x: scroll;
  padding: $presentParticipantListInnerPadding;
  padding-bottom: calc( $presentParticipantListToggleButtonHeight + $presentParticipantListInnerPadding );
  transition: bottom .5s ease;
  max-height: 100vh;
}
.presentParticipantList.showList .presentParticipantList__inner {
  bottom: 0;
}

.presentParticipantList__section:not(:last-of-type) {
  border-bottom: 1px solid $darkGrey;
  margin-bottom: 0.15rem;
  padding-bottom: 0.15rem;
}

.presentParticipantList__section h2 {
  font-size: 0.65rem;
  margin: 0;
  text-align: left;
}

.presentParticipantList__sectionList {
  flex-direction: column;
}

.presentParticipantList__sectionList,
.presentParticipantList__participant {
  list-style: none;
  margin: 0;
  padding-left: 0.15rem;
  text-align: left;
  width: 100%;

  /* DISPLAY */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.presentParticipantList__participant {
  text-overflow: ellipsis;
  transition: transform 0.5s ease;
  white-space: nowrap;
  width: 100%;
  align-items: center;
}

.presentParticipantList__participant.muted {
  color: $lightGrey;
}
.presentParticipantList__participant.loudest {
  color: $BrightPeach;
  font-weight: 900;
  transform: scale(1.05);
}

.presentParticipantList__muteIcon {
  font-size: 0.95em;
  margin-right: 0.15rem;
}

.presentParticipantList__promoteIconSuggested {
  background-color: #0e9943;
  border-radius: 9px;
  font-size: 0.8em;
  margin-left: 0.2rem;
  opacity: .75;
  padding: .25rem;

  transition: .2s ease all;
}

.presentParticipantList__promoteIconNotSuggested {
  background-color: #c12933;
  border-radius: 9px;
  font-size: 0.8em;
  margin-left: 0.2rem;
  opacity: .75;
  padding: .25rem;

  transition: .2s ease all;
}

.emoji-crown {
  font-size: 1.5em;
  display: inline-flex; /* Use flexbox for alignment */
  align-items: center;  /* Center vertically */
  justify-content: center; /* Center horizontally if needed */
  line-height: 1; /* Adjust line height to ensure proper vertical alignment */
  transform: translateY(-0.1em); /* Nudge the emoji upwards */
}

.emoji-new {
  font-size: 1.4em;
}


.presentParticipantList__promoteIconSuggested:hover {
  background-color: #405ebe;
  cursor: pointer;
}

.presentParticipantList__promoteIconSuggested path {
  fill: white;
}

.presentParticipantList__promoteIconNotSuggested:hover {
  background-color: #405ebe;
  cursor: pointer;
}

.presentParticipantList__promoteIconNotSuggested path {
  fill: white;
}



.presentParticipantList__toggleButton {
  /* POSITION */
  position: absolute;
  left: 0.15rem;
  right: 0.15rem;
  bottom: 0.15rem;

  background: $darkGrey;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 3px 6px #0000007b;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: $presentParticipantListToggleButtonHeight;
  transition: background-color .25s ease, border .25s ease;

  /* Unset prefixed browser-specific appearances */
  -webkit-appearance: none;
  -moz-appearance: none;
}
.presentParticipantList.showList .presentParticipantList__toggleButton {
  background: $Peach;
}

.presentParticipantList__debroadcastButton {
  align-self: flex-end;
  margin-left: .2rem;
}

.presentParticipantList__debroadcastButton.willDebroadcast:hover {
  cursor: pointer;
}

.participantList__broadcastIcon {
  background: #f4ac60;
  border-radius: 1000px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-size: 0.85em;
  padding: 0.5em;
  width: 2.25em;
}

.participantList__broadcastIcon:hover {
  background: #da954b;
}

.participantList__broadcastIcon.negativeState {
  background: #ffe2c4;
  color: gray;
}

.participantList__broadcastIcon.negativeState:hover {
  background-color: #e0bf9c;
}