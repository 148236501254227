.ratingStars {
  margin: 0.25rem;
  text-align: center;
}

.ratingStars__star {
  width: 1rem;
}

.ratingStars__showValue {
  font-size: 0.75em;
  margin-left: 0.5rem;
}