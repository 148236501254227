// Import global styles
@import '../../global.scss';

.loadingOverlay {
  /* DISPLAY */
  display: flex;
  justify-content: center;
  align-items: center;

  /* POSITION */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // background is $darkGray with opacity
  background: linear-gradient(rgba(77, 69, 65, 0.9), rgba(77, 69, 65, 0.9));
  // Anti-pattern: z-index set arbitrarily high to always put it on top
  z-index: 100; 
}

.loadingOverlay__content {
  background: #fff;
  border-radius: 10px;
  color: $BrightPeach;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 2rem;
}
