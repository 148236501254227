// Import global styles
@import '../../../global.scss';

.communicator {
  /* DISPLAY */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.25rem;
  padding-right: 0.25rem;
}

.communicator__menuButton {
  background: lighten($darkGrey, 25%);
  border-radius: 1000px;
  box-shadow: $shadow;
  box-sizing: border-box;
  color: white;
  font-size: 0.75rem;
  padding: 0.25em 0.5em;

  &:hover {
    cursor: pointer;
  }
}

.communicator__clock {
  font-family: monospace;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 15px;
}

.communicator__clock.fiveMinWarning {
  color: red;
}
