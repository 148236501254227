.manage__newDrill {
  margin: 1rem;
}

.newLesson__form > * {
  display: block;
  margin: 0.25rem;
}

.newDrill__label {
  display: flex;
  align-items: center;
}

.newDrill__input {
  margin-left: 0.25rem;
}

.newDrill__select {
  font-size: 0.75rem;
  margin-left: 0.25rem;
}

.newDrill__error {
  color: red;
  font-size: 0.8em;
  font-weight: 800;
  margin-top: -0.25rem;
}

.newDrill__createdLessonsContainer {
  background: #ddd;
  margin-left: 0.5rem;
  padding: 0.25rem;
}

.newDrill__heading {
  margin: 0;
  font-size: 1.5em;
  text-align: center;
}

.newDrill__createdLessons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .lesson-summary {
    margin: 0.25rem;
  }
}
