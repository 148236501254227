// Import variables
@import "../../global.scss";

.teachContainer {
  /* DISPLAY */
  display: flex;
  flex-flow: row nowrap;

  /* POSITION */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  background: #fff;
  overflow-y: hidden;

  &.muted {
    background: linear-gradient(rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.3)), #fff;
  }
}

.teach__port {
  display: flex;
  flex-flow: column nowrap;

  box-sizing: border-box;
  height: 100%;
  padding: 0.25rem;
  position: relative;
  width: 63%;
}

.teach__starboard {
  box-sizing: border-box;
  padding: 0.25rem;
  width: 37%;
}

.teach__version {
  /* POSITION */
  position: fixed;
  bottom: 0.25rem;
  left: 0.25rem;

  color: $Peach;
  font-size: 0.5rem;
}