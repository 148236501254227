.classDetail__classStartArea {
  text-align: center;
}

.classDetail__classStartArea p {
  margin: 0.25rem 0;
}

.classDetail__classStartButton {
  background: none;
  border: 3px solid blue;
  border-radius: 1rem;
  box-shadow: none;
  color: blue;
  cursor: pointer;
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
  margin: 0 auto 0.25rem;
  padding: 0.5rem 1rem;
}

.classDetail__classIsKilled {
  color: red;
  font-weight: 500;
}
