@import "../../../../global.scss";

.teacherReviews__reviewTable {
  border-collapse: collapse;
  margin: 1rem auto;
  max-width: 95vw;
}

.reviewTable__header {
  background: $darkGrey;
  border: 1px solid white;
  color: #eee;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.25rem;

  &:hover {
    background: #000;
    color: #fff;
  }

  &--activeIndex, &--activeIndex:hover {
    background: $purple;
    color: #fff;
  }
}

.teacherReviews__reviewTable td {
  border: 1px solid black;
  font-size: 0.8rem;
  padding: 0.25rem;
}
