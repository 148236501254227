// Import variables
@import "../../global.scss";

.classFeedbackWidget {
  background: $darkGrey;
  border-radius: 25px;
  box-shadow: $shadow;
  color: white;
  cursor: pointer;
  margin: 0 auto 1rem;
  padding: 1rem;
  width: fit-content;
}

.classFeedbackWidget__heading {
  font-size: 1.1rem;
  margin: 0 auto;
  text-align: center;
}