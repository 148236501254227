// All styles for header component

// Import variables
@import "../../global.scss";

// Container styling
.headerContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 1rem 0;
  padding: 0.5rem 0;
  position: sticky;
  top: 0;
  z-index: 1; // Fixes weird issue with datepicker rendering on top of header
}

// Styling for Quokka
.header-logo{
  height: 2rem;
}

// Bienvenid@, [teacher]
.header-welcome {
  color: $darkGrey;
  font-size: 0.8rem;
  margin: 0 0.25rem;
  text-align: center;

  &__name {
    white-space: nowrap;
  }
}

// Clock styles
.nyc-clock {
  background: #ddd;
  border: 1px solid black;
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
  text-align: center;

  .nyc-clock__time {
    font-family: monospace; // To keep clock width from changing on tick
    font-size: 0.75rem;
  }

  .nyc-clock__label, .nyc-clock__date {
    font-size: 0.5rem;
  }
}

// Background is separate div to keep box-shadow underneath expanded menus
.headerBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $blue;
  box-shadow: $shadow;
  z-index: -1;
}

// At 950px wide, wrap teacher name
@media only screen and (max-width: 950px) {
  .header-welcome__name {
    display: block;
  }
}

// At tiny breakpoints, make the welcome text even smaller and hide logo
@media only screen and (max-width: 480px) {
  .header-logo {
    display: none;
    visibility: hidden;
  }

  .header-welcome {
    font-size: 0.7rem;
  }
}
