// Import global styles
@import '../../../global.scss';

.commandStationContainer {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.commandStation__topRow {
  /* DISPLAY */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.commandStation__button {
  background: $purple;
  border-radius: 1000px;
  box-shadow: $shadow;
  box-sizing: border-box;
  color: white;
  font-size: 0.7em;
  padding: 0.25em 0.5em;

  &:hover {
    cursor: pointer;
  }
}
.commandStation__button.inactive {
  background: $darkGrey;
  color: lighten($darkGrey, 40%);

  &:hover {
    cursor: default;
  }
}

.topArrows__icon {
  font-size: 30px;
  margin: 0.25rem 0.35rem;

  &:hover {
    cursor: pointer;
  }
}

.commandStation__bottomRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.commandStation__button.modeButton.active {
  background: $Peach;
}

.commandStation__allModeLabel {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25.5px;
}

/* Hide default checkbox */
.commandStation__allModeInput {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The [a] + [b] selector matches the first instance of [b] after [a] - useful for selecting one sibling when the state of another sibling changes */
.commandStation__allModeInput:checked + .commandStation__allModeSlider {
  background: $Peach;
}

.commandStation__allModeInput:focus + .commandStation__allModeSlider {
  box-shadow: 0 0 1px $BrightPeach;
}

.commandStation__allModeInput:checked + .commandStation__allModeSlider:after {
  transform: translateX(19.5px);
}

.commandStation__allModeSlider {
  /* POSITION */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 25.5px;
  background-color: #ccc;
  cursor: pointer;
  transition: .4s;
  -webkit-transition: .4s;
}
.commandStation__allModeSlider:before {
  /* POSITION */
  position: absolute;
  left: -2.75rem;
  top: 50%;

  content: "All Speak:";
  font-size: 0.6rem;
  transform: translateY(-50%);
}
.commandStation__allModeSlider:after {
  /* POSITION */
  position: absolute;
  left: 3px;

  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  content: "";
  height: 19.5px;
  transition: .4s;
  width: 19.5px;
}

/* Adjustments for small-height viewports */
@media only screen and (max-height: 400px) {
  
  /* Remove slide arrow bottom margin to save vertical space */
  .topArrows__icon {
    margin-bottom: 0;
  }
}