// Import global styles
@import '../../../../global.scss';

.commandStation__studentPresence {
  font-size: 0.65rem;
  margin-top: 0.25rem;
  text-align: center;
}

.studentPresence__oneSpeaker {
  /* DISPLAY */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  padding: 0.25em;

  &.debroadcasted {
    color: gray;
  }
}

.studentPresence__muteIconContainer {
  width: 1.75em;
}

.studentPresence__muteIcon {
  color: green;
}
.studentPresence__muteIcon.negativeState {
  color: red;
}

.oneSpeaker__name {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  flex-grow: 1;
  font-size: 1.1em;
  overflow: hidden;
  padding: 0.15rem 0.25rem;
  white-space: nowrap;
}
.oneSpeaker__name.isCurrent {
  color: gray;
}

.oneSpeaker__name .oneSpeaker__shortcutHint {
  background: $darkGrey;
  border: 1px solid $Peach;
  border-radius: 0.25em;
  color: #fff;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 900;
  height: 1em;
  line-height: 1em;
  margin-right: 0.5rem;
  width: 1em;

  // Hide inactive hint (convo mode)
  &.inactive {
    display: none;
    visibility: hidden;
  }
}

.studentPresence__button {
  background: $Peach;
  border-radius: 1000px;
  box-shadow: $shadow;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-size: 0.85em;
  padding: 0.5em;
  width: 2.5em;

  &.negativeState {
    background: $lightPeach;
    color: gray;
  }
}

.studentPresence__invisibleStudentsCounts {
  font-size: 0.5rem;
  padding-bottom: 0.1rem;
}

/* At very small height viewports, slightly decrease student presence font size to fit up to 6 Speakers on the screen in convo mode */
@media only screen and (max-height: 325px) {
  .commandStation__studentPresence {
    font-size: 0.6rem;
  }
}

/* Hide keyboard shortcut hints on mobile */
@media only screen and (max-device-width: 900px) {
  .oneSpeaker__shortcutHint {
    display: none;
    visibility: hidden;
  }
}
