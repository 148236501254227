.versionCheckModal {
    .modal-body{
        width: 60vw;
        height: fit-content;
        margin: 10vh 20vw;
        border-radius: 30px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        z-index: 100;
    }

    div.supportInfo {
        padding: 20px;
        text-align: center;
    }
    div.modal-content {
        height: fit-content;
        width: fit-content;
    }

    div.versionOutdatedRefreshButton {
        background-color: #BD5700;
        border-radius: 1000px;
        color: white;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        font-weight: 600;
        margin: .75em auto;
        min-width: 90px;
        padding: .75em 1.25em;
        text-align: center;
        transition: all .2s ease;
        width: fit-content;
    }
}
