// Import variables
@import "../../../global.scss";

.waitingRoom {
  /* DISPLAY */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  
  /* POSITION */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  z-index: 100; // Not best practice: aribrarily high z-index
}

.waitingRoom__proceedButton {
  background: $Peach;
  border-radius: 1000px;
  box-shadow: 0 3px 6px #0000007b;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  padding: 0.25em 0.5em;
}