/* My vote is that we deprecate all of these in favor of semantic styles that are scoped to their component, and live in component-specific stylesheets */

// Import variables and fonts
@import "global.scss";

html, body{
    font-family: ProximaNova, sans-serif;
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    font-size: 23px;
  }

button:focus{
  outline: 0;
}

.bold {
  font-weight: 600;
}

.booking-fields {
  padding: 1vw;
}

.forgot-message{
  font-size: 0.6em;
}

.form{
  width: 50vw;
  line-height: 8vh;
  font-size: 0.7em;
  border-radius: 25px;
  outline-width: 0;
}
.form:focus{
  border-color: $Peach;
}

.here{
  color: $blue;
}

.here:hover{
  cursor: pointer;
}

.hidden {
  opacity: 0;
}

.incomplete-message{
  color: red;
  font-size: 0.6em;
}

// modal styles
.modal{
  position: fixed; /* Stay in place */
  z-index: 9; /* Sit on top */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-body{
  background-color: white;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  box-sizing: border-box;
  height: 80vh;
  margin: auto;
  padding: 1rem;
  width: 80vw;
  z-index: 10;
}

.modal-text{
  margin: 3vh 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 80%;
}

.modal-text-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2vh;
}

.red{
  color:red
}

/* RESET PASSWORD */
.reset-section{
  width: 100vw;
  height: 90vh;
  font-size: 1.2em; 
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/*** Sign-in ****/
.SignIn{
  width: 100vw;
  margin-top: 15vh;
  margin-bottom: 15vh;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.SignUp{
  width: 100vw;
  margin-top: 15vh;
  margin-bottom: 15vh;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.signup-field{
  margin-bottom: 1vh;
  width: 100vw;
  text-align: center;
}

.signin-header{
  font-size: 2em;
}

.submit-button{
  width: 30vw;
  border-radius: 25px;
  font-size: 1.4em;
  line-height: 10vh;
  background-color: $Peach;
  color: white;
  border: none;
}

.submit-button:hover{
  background-color: $blue;
  border-color: $Peach;
  cursor: pointer;
  transition: ease-in-out 0.5s;
}

.submit-button[disabled], .submit-button[disabled]:hover{
  background-color:$darkGrey;
  cursor:default;
  border-color:black;
}

.x-out{
  width: 2%;
  position: relative;
  left: 2%;
  top: 2%;
  font-size: 1.3em;
}

.x-out:hover{
  cursor: pointer;
  color: red;
}
