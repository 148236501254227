// Import variables
@import "../../../global.scss";

// Displays "Manage" and icon, click to expand menu
.manage-menu__label {
  margin: 0;
  padding: 0;
}

// Icon next to "Manage" text
.manage-menu__icon {
  margin-left: 0.25rem;
  position: relative;
  top: 2px;
}

// ul that contains li's with the actual links
.manage-menu__items {
  background: $blue;
  box-shadow: $shadow-notop;
  list-style: none;
  margin: 0;
  opacity: 0;
  padding: 0.5rem;
  position: absolute; // Positioned against entire header container
  left: 0;
  right: 0;
  top: -100%;
  text-align: center;
  transition: all 0.5s ease;
  
  li {
    list-style: none;
    display: inline-block;
  }

  // Styles for when it's visible
  &.open {
    opacity: 1;
    top: 100%;
  }
}

// The actual links in the manage menu
.manage-menu__link {
  display: inline-block;
  margin: 0.25rem;
}

// When hamburger menu displays, Manage menu should always be visible
@media only screen and (max-width: 750px) {
  // Hide label and icon
  .manage-menu__label, .manage-menu__icon {
    display: none;
    visibility: hidden;
  }

  // Manage menu items container should appear inline
  .manage-menu__items, .manage-menu__items.open {
    box-shadow: none;
    opacity: 1;
    padding: 0;
    position: relative;
    top: 0;
    z-index: 0;

    // Manage menu items should appear inline like other links
    li {
      display: block;
    }
  }
}
