.moduleSearchBar {
    margin: auto;
    padding: 10px;
    width: 35vw;
}

.moduleDetailsView {
    width: 100%;

    .moduleInformation {
        grid-column: 1;
        padding: 2%;

        .textField {
            font-size: 1em;
        }
    }

    .moduleSlides {
        grid-column: 2;
        margin-top: 10px;
        height: 600px;

        .googleSlidesIframe {
            margin: 2%;
            width: 50%;
            height: 100%;
        }

        .slidesDotComIframe {
            margin: 2%;
            width: 50%;
            height: 100%;
        }
    }
}

.moduleDetailsAddEdit {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;

    .moduleInformation {
        grid-column: 1;
        padding: 2%;

        .textField {
            font-size: 1em;
        }
    }

    .moduleSlides {
        grid-column: 2;
        margin-top: 10px;

        .googleSlidesIframe {
            margin: 2%;
            width: 80%;
            height: 40%;
        }

        .slidesDotComIframe {
            margin: 2%;
            width: 80%;
            height: 40%;
        }
    }
}

.moduleData {
    display: grid;
    grid-template-columns: 300px 300px;
    grid-template-rows: auto;
    margin-left: 100px;
    margin-top: 20px;
    border: 1px solid black;
    row-gap: 0px;
    width: 600px;
}

.moduleDataColumn1 {
    grid-column: 1;
    font-size: 20px;
    margin-left: 50px;
    text-decoration: underline;
    font-weight: 900;
}

.moduleDataColumn2 {
    grid-column: 2;
    font-size: 18px;
    overflow: auto;
}

.cell {
    grid-column: 1 / -1;
    height: 1px;
    background: black;
  }

.centerLabel {
    margin-top: 30px;
}

.linkToModule {
    text-align: center;
    margin-right: 50px;
}

.moduleDetailsViewOptions  {
    display: flex;
    justify-content: space-around;
    width: 800px;
}

.moduleDetailsViewOption {
    cursor: pointer;
}

.selected {
    text-decoration: underline;
    cursor: pointer;
    color: #febd69;
    font-weight: 900;
}

.input {
    width: 500px;
    height: 40px;
    font-size: 23px;
}

select {
    font-size: 20px;
}

.label {
    text-decoration: underline;
    
}

.input-field {
    margin-top: 15px;
    width: 400px;
}

.add-module-submit-button {
    width: 200px;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    margin-left: 180px;
    background-color: #f4ac60;
    cursor: pointer;
}

.add-module-submit-button-disabled {
    width: 200px;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    margin-left: 180px;
    background-color: #f4ac60;
    cursor: pointer;
    opacity: .6;
}

.add-module-success-message {
    color: green;
    text-align: center;
    width: 600px
}

.add-module-error-message {
    color: red;
    text-align: center;
    width: 600px
}

.lesson-name-error-message {
    color: red;
    text-align: center;
    width: 600px;
    font-size: 18px;
    margin-left: -50px;
}

.modulePageLink {
    color: purple;
    cursor: pointer;
}

.read-only-input {
    width: 500px;
    height: 40px;
    font-size: 23px;
    border: 1px solid black;
    text-decoration: none;
    background-color: gray;
    color: white;
}