.deflectorShield {
/* DISPLAY */
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  box-sizing: border-box;
  height: 100vh;
  max-height: 100%;
  overflow-y: hidden;
  padding: 2rem;
}
