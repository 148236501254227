// Import variables and fonts
@import "../../../global.scss";

.lessonReportWebDrillModal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.lessonReportWebDrill__x-out {
  /* DISPLAY */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  /* POSITION */
  position: absolute;
  top: 1rem;
  left: 1rem;

  background: $lightBlue;
  border-radius: 50%;
  font-size: 1.3em;
  height: 50px;
  width: 50px;
}
.lessonReportWebDrill__x-out:hover{
  cursor: pointer;
  color: red;
}

.lessonReportWebDrillModalBody {
  width: 90vw;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.lessonReportWebDrillModalHeader {
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-size: 1.6em;
  font-weight: bold;
}
  
.lessonReportWebDrillModalSection {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  background: $lightBlue;
  margin-top: 2vh;
  border-radius: 25px;
  padding: 1vw 1vh;
}

.lessonReportWebDrillModalSectionHeader {
  font-size: 1.2em;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid black;
  margin-bottom: 1vh;
}

.text-area-autosize-WebDrill{
  border-radius: 25px;
  box-sizing: border-box;
  font-size: 0.85em;
  padding: 10px 10px;
  width: 100%;
  min-width: 300px;
  display: block;
}

.text-area-autosize-WebDrill hidden {
  display: none; 
}

.lessonReportWebDrillSubmitButton {
  width: 30vw;
  border-radius: 25px;
  font-size: 1.4em;
  line-height: 10vh;
  background-color: $Peach;
  color: white;
  border: none;
  margin: 20px;
  cursor: pointer;
}

.lessonReportWebDrillNoModule {
  color: red;
}

.lessonReportWebDrillLoadingError {
  color: red;
}

.lessonReportWebDrillServerError {
  color: red;
}

.lessonReportWebDrillInputError {
  color: red;
}

.lessonReportWebDrillImportantMessage {
  color: red;
  font-weight: bold;
  padding: 1rem 1rem 0;
}

.lessonReportWebDrillBoldText {
  font-weight: bold;
}

.lessonReportWebDrillSmallText {
  font-size: 0.8em;
}

.lessonReportWebDrillCheckbox {
  text-align: left;
}

.lessonReportWebDrillStudentList__heading {
  font-size: 1.25em;
  font-weight: 600;
  margin: 0.5em;
  text-align: center;
}

.lessonReportWebDrillStudentList {
  display: grid;
  column-gap: 1rem;
  margin-top: 1rem;

  .studentNameLabel {
    grid-column: 1;
    text-decoration: underline;
  }

  .studentName {
    grid-column: 1;
  }

  .bbCountLabel {
    grid-column: 3;
    text-decoration: underline;
  }

  .bienCountLabel {
    grid-column: 4;
    text-decoration: underline;
  }

  .feedbackLabel {
    grid-column: 5;
    text-decoration: underline;
  }

  .bbCount {
    grid-column: 3;
  }

  .bienCount {
    grid-column: 4;
  }

  .feedbackInput {
    grid-column: 5;
    min-width: 150%; // Minimum width should be at least 75% of modal's width
  }
}
