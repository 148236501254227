@import "../../../global.scss";

.classDetail__changeModuleModalOverlay {
  /* DISPLAY */
  display: flex;
  justify-content: center;
  align-items: center;

  /* POSITION */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7));
  font-size: 0.9rem;
}

.classDetail__changeModuleModalInner {
  background: white;
  border-radius: 1rem;
  padding: 1rem;
  max-width: 67vw;
  min-width: 320px;
}

.changeModuleModal__currentModule {
  margin-bottom: 0.5rem;
}

.changeModuleModal__buttonsContainer {
  margin: 0.5rem auto;
  text-align: center;
}

.changeModuleModal__button {
  background: white;
  border: 2px solid $Peach;
  border-radius: 1rem;
  color: $Peach;
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 1rem 0;
  padding: 0.5rem 1rem;

  &--cancel {
    border: 2px solid red;
    color: red;
  }
}
